@tailwind base;
@tailwind components;
@tailwind utilities;

@import "reset";
@import "app";
@import "pagination";
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #232323;
}
#container {
  display: flex; /* establish flex container */
  flex-direction: column; /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  height: 300px;
}
.select-css::-webkit-scrollbar {
  width: 8px;
}
.select-css::-webkit-scrollbar-thumb {
  background-color: orange;
  height: 20px;
  opacity: 0.5;
  border-radius: 8px;
}
.select-css::-webkit-scrollbar-track {
  background-color: #fff;
}
.box {
  width: 300px;
  margin: 5px;
  text-align: center; /* will center text in <p>, which is not a flex item */
}
/*-------------------------------------------------------*/
.button {
  max-width: 200px;
  --color: #fe8b52;
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  outline: none;
  position: relative;
  isolation: isolate;
  transition: all 0.3s ease-in;
}
.button:hover {
  color: #fff;
}
.button:before {
  content: "";
  height: 100%;
  width: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--color);
  border-radius: 4px;
  z-index: -1;
  transition: width 0.3s ease-in;
}

.button:hover:before {
  width: 100%;
}
/*-----------------------------css input--------------------------*/

$primary1: #4d47c3;
$secondary2: #38ef7d;
$white3: #fff;
$gray4: #9b9b9b;
.form__group {
  position: relative;
  padding: 15px 0 0;
}

.form__field {
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray4;
  outline: 0;
  font-size: 1rem;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray4;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 0.8rem;
    color: $primary1;
    font-weight: 700;
  }
  padding-bottom: 6px;
  font-weight: 500;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary1, $secondary2);
  border-image-slice: 1;
}
/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}
/*-------------------------------------------------------*/
.hidden-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
/*-----------------------------layout location-detail--------------------------*/

.location-detail {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  gap: 30px 50px;
}

// Css Room
.room-layout {
  max-width: 260px;
  width: 100%;
  background-color: #f1f0fa;
  border: 1px solid #ffffff99;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 1340px) {
  .room-layout {
    max-width: 300px;
    width: 300px;
  }
}

/*----------------------------info room---------------------------*/

.info__basic-layout {
  width: 100%;
  background: #fff;
  border: 40px;
  padding: 10px 20px;
  position: relative;
  z-index: 10;
  border-radius: 20px;
  margin-top: 26px;
  opacity: 0.9;
}
.info__basic-layout::before {
  content: "";
  width: 90%;
  height: 100px;
  background: #fff;
  opacity: 0.3;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -10px);
  z-index: -1;
}
.sidebar {
  width: 200px;
  height: 100%;
  min-height: 100vh;
  .sidebar-logo {
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 0 20px;
    img {
      max-width: 40px;
    }
    margin-bottom: 20px;
    padding: 20px 20px 0;
  }
}
.sidebarHome,
.sidebarUnit {
  width: 250px;
  height: 100%;
  min-height: 100vh;
  .sidebar-logo {
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 0 20px;
    img {
      max-width: 40px;
    }
    margin-bottom: 20px;
    padding: 20px 20px 0;
  }
}
.sidebarHome {
  background: #fff;
  box-shadow: rgb(227, 237, 20) px 20px rgba(218, 213, 213, 0.3);
  .menu-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 15px;
    margin: 0 20px;
    font-weight: 500;
    color: gray;
    margin-bottom: 10px;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: #fe8b52;
      color: black;
      border-radius: 8px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100px;
    .menu-item {
      justify-content: center;
    }
    .menu-text {
      display: none;
    }
  }
}
.sidebarUnit {
  background: #fff;
  box-shadow: 10px 10px 20px rgba(218, 213, 213, 0.3);
  .menu-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 15px;
    font-weight: 500;
    color: gray;
    margin-bottom: 10px;
    margin: 5px 10px;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: #ff9560;
      color: black;
      border-radius: 12px;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100px;
    .menu-item {
      justify-content: center;
    }
    .menu-text {
      display: none;
    }
  }
}
.base-header-on-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    width: fit-content;
    position: absolute;
    top: 10px;
    right: 16px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 8px;

    // box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);

    &--expand {
      border-radius: 50%;
      padding: 10px;
    }

    &--collapsed {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  }
}

.sidebar {
  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(218, 213, 213, 0.3);
  .menu-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 15px;
    font-weight: 500;
    color: gray;
    margin: 5px 10px;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: #ff9560;
      color: black;
      border-radius: 12px;
      font-weight: 600;
    }
  }
  @media screen and (max-width: 768px) {

    &.is-expand {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      .menu-item {
        padding: 8px 10px;
        margin: 5px;
      }

      & > h1 {
        text-align: left !important;
        padding: 0 5px;
        width: 100%;
  
        button {
          .btn-home-back {
            justify-content: flex-start;
          }
        }
      }
    }

    &.is-collapsed {
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    & > h2 {
      display: none;
    }
  }
}
/*------------------------dashboard layout-----------------------*/

.dashboard {
  // max-width: 1440px;
  margin: 0 auto;
  &-title {
    width: 100%;
    background-color: white;
    white-space: nowrap;
    overflow: hidden;
  }
  &-sub {
    display: inline-block;
    color: gray;
    padding-left: 100%;
    animation: move 25s linear infinite;
  }
  &-main {
    display: grid;
    grid-template-columns: 250px minmax(0, 1fr);
    gap: 0 20px;
    align-items: start;
  }
  &-room {
    &-main {
      display: grid;
      grid-template-columns: 200px minmax(0, 1fr);
      // gap: 0 20px;
      align-items: start;
    }
  }
}
@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

/*----------------------------dashboard__card--info---------------------------------*/

.dashboard__card--info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px 50px;
  margin-top: 20px;
}

/*-------------------layout table--------------------*/
.table-layout {
  overflow-x: auto;
  background-color: white;
  border-radius: 10px;
  // margin: 0 15px;
}

table {
  width: 100%;
  // table-layout: fixed;
}
thead {
  background-color: #e4e4ea;
}
th,
td {
  vertical-align: middle;
}
th {
  padding: 15px 20px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  user-select: none;
}
td {
  padding: 5px 15px;
  width: 50px;
}
tr {
  border-bottom: 1px solid #eee;
}
tr:nth-last-child(1) {
  border-bottom: none;
}
@media screen and (max-width: 768px) {
  table {
    & th {
      padding: 15px;
      font-size: 14px;
    }
    & td {
      padding: 5px 10px;
      font-size: 13px;
    }
  }
}
/*-----------------------end------------------------*/

/*-----------------------MODAL------------------------*/

.react_modal_custom_style {
  display: block;
  border: none !important;
  background: transparent !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  overflow-y: auto;
  width: 700px;
}

@media screen and (max-width: 768px) {
  .react_modal_custom_style {
    width: 400px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 700px;
  padding: 2rem;
}
.modal-header {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.modal-close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
  padding: 5px 10px;
  border-radius: 100%;
}
.modal-close-button:hover {
  background-color: #a7a5a5;
}
/*-----------------------Setting Page-------------------------*/

.settingBar {
  width: 100%;
  height: auto;
  background: #f6f8fb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .menu-item {
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: gray;
    border-radius: 4px;
    cursor: pointer;
    &.active {
      background-color: #f0efff;
    }
    .content {
      position: relative;
      &:after {
        content: "";
        width: 0;
        height: 3px;
        background: #216ba5;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        transition: all 0.2s;
      }
      &:hover::after {
        width: 50px;
      }
    }
  }
}
.form-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
/*----------------------------------------------*/

/*-----------------------machine room-----------------------------*/
.header-machine:hover .setting-machine {
  display: block;
}
.example-custom-input {
  cursor: pointer;
  padding: 5px 15px;
  border: 0;
  border-radius: 4px;
  background-color: #216ba5;
  color: #fff;
  font-size: 14px;
}
.disable-example-custom-input {
  cursor: auto;
  padding: 3px 15px;
  border: 0;
  border-radius: 4px;
  background-color: #cccccc65;
  color: #ccc;
  font-size: 14px;
}
/*--------------------css library react-excel---------------------*/

.react-excel {
  margin: 10px 0;
  margin-right: 10px;
  color: #2c3d63;
  border: 1px solid gray;
  border-radius: 10px;
  & div:first-child {
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  & button {
    border: 1px solid #31958d;
    padding: 5px 10px;
    border-radius: 4px;
    transition: all 0.25s;
    user-select: none;
  }
  & button:hover {
    transform: scale(1.1);
  }
  & table {
    width: 100%;
    margin-top: 10px;
    & th {
      padding: 10px 5px;
      border: 1px solid gray;
    }
    & td {
      padding: 8px 5px;
      font-size: 14px;
      border: 1px solid #ccc;
    }
  }
}
.active-sheet {
  background-color: #216ba5;
  color: white;
}
@media screen and (max-width: 768px) {
  .dashboard {
    &-main {
      display: grid;
      grid-template-columns: 100px minmax(0, 1fr);
      gap: 0 20px;
      align-items: start;
    }
    &-room {
      &-main {
        // display: grid;
        // grid-template-columns: 0 minmax(0, 1fr);
        // gap: 0 10px;
        // align-items: start;
        display: flex;
        flex-direction: column;

        .dashboard-children {
          max-width: 100vw;
          overflow-x: hidden;
          padding-right: 8px;
          height: 100vh;
        }
      }
    }

  }
  .form-layout {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .react-excel {
    & button {
      font-size: 12px;
    }
    & table {
      & th {
        font-size: 14px;
        padding: 8px 4px;
      }
      & td {
        padding: 6px 3px;
        font-size: 12px;
      }
    }
  }
}

/* DropdownSearch.css */
.dropdown-search {
  position: relative;
  // width: 500px;

  & input {
    width: 100%;
    padding: 8px;
  }
  & input:focus {
    border-color: #216ba5;
  }

  & ul {
    position: absolute;
    list-style: none;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ddd;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
  }

  & li {
    padding: 8px;
    cursor: pointer;
  }

  & li:hover {
    background-color: #f2f2f2;
  }
}

// Css of dropdawn library
._2zTZb {
  width: 400px;
  ._2yQu9 {
    position: absolute;
    top: 50%;
    right: 5px;
    height: 30%;
    transform: translateY(-50%);
    display: flex;
    width: 25px;
    align-items: center;
    border-radius: 0.15rem;

    justify-content: flex-start;
    color: rgb(55, 65, 81);
    z-index: 5;
    cursor: pointer;

    &:hover {
      background-color: rgb(55, 65, 81);
      svg {
        fill: #d1d5db;
      }
    }
    background-color: white;
    padding: 5px 0px;
    & > svg {
      height: 15px;
    }
  }
}

/*-------------------------------------------*/
// DatePicker
.react-datepicker-wrapper {
  width: auto !important;
}

/*-------------------------------------------*/
.switch {
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 27px;
  display: block;
  margin-left: 40%;
  transform: translateX(-50%);
  overflow: hidden;
  border: #9b9b9b 1px solid;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 100rem;
  transition: background-color 0.25s linear;
}

.switch:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 40px;
  background-color: #ccc;
  top: 3px;
  left: 5px;
  transition: background-color 0.25s linear, transform 0.25s linear;
}

.switch-input {
  display: none;
}

.switch-input:checked + .switch {
  background-color: #6a5af9;
}

.switch-input:checked + .switch:after {
  transform: translateX(30px);
  background-color: white;
}
// Item dropdown
.dropdow_graph > div > div > .css-3w2yfm-ValueContainer > div {
  width: 70px !important;
}

.select-fulltext {
  div[class$="-control"] {
    & > div {
      display: flex;
      flex-wrap: nowrap;
      & > div {
        width: auto !important;
      }

      div[class$="-multiValue"] {
        width: auto !important;
      }
      div[class$="-MultiValueGeneric"] {
        width: auto !important;
      }
    }
  }
}

/*-------------------------------------*/

.info {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}
.info__title--temp {
  color: rgb(223, 75, 75);
}
.info__title--humi {
  color: rgb(104, 87, 87);
}
.main__title {
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  color: rgb(46, 172, 130);
  padding-bottom: 50px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.form__temp {
  width: 70%;
  margin: 0 auto;
  padding: 15px 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.temp--label {
  margin-right: 20px;
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  width: 150px;
}
.temp--input {
  /* width: 80%; */
  border: none;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 10px;
  flex: 1;
}
.temp--input:focus-visible {
  outline: 1px solid #1f7507 !important;
}
.btn {
  width: 200px;
  margin: 0 auto;
  border: none;
  padding: 15px 25px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 400;
}
.btn--pub {
  background-color: #6ce44b56;
  transition: 0.5s all;
}
.btn--pub:hover {
  background-color: #6ae248;
  color: white;
}
.error {
  color: rgb(233, 75, 75);
  font-size: 14px;
  font-weight: 400;
  width: 200px;
  margin-left: 10px;
}
.br {
  margin-bottom: 100px;
}

.qrCode:hover > .reset_qr_code {
  display: block;
}

@for $i from 1 through 10 {
  .o-line-clamp--#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;  
    overflow: hidden;
    word-break: break-word !important;
  }
}