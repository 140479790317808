html {
  background-color: rgb(245, 245, 242);
}

.main {
  // max-width: 1440px;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
// @media screen and (max-width: 1340px) {
//   .main {
//     max-width: 1280px;
//   }
// }
